import React from "react";
import ReactDom from "react-dom";
import "./index.css";

const Greeting = () => {
  return (
    <React.Fragment>
      <h4>
        Welcome to my blog.
        <br /> Coming soon.
      </h4>
    </React.Fragment>
  );
};

ReactDom.render(<Greeting />, document.getElementById("root"));
